import * as Sentry from "@sentry/react";
import isString from "lodash/isString";

import assertNever from "./util/assertNever";

let monitoringInited = false;

export function init() {
  const dsn = sentryDsn();

  if (dsn !== null) {
    // "development" is for local development environment
    const foodstepsEnvironment: "development" | "dev" | "staging" | "live" = (
      window as any
    ).FOODSTEPS_ENVIRONMENT;

    let tracePropagationTarget: string;

    if (foodstepsEnvironment === "development") {
      tracePropagationTarget = "localhost:8000";
    } else if (foodstepsEnvironment === "dev") {
      tracePropagationTarget = "dev.platform.foodsteps.earth";
    } else if (foodstepsEnvironment === "staging") {
      tracePropagationTarget = "staging.platform.foodsteps.earth";
    } else if (foodstepsEnvironment === "live") {
      tracePropagationTarget = "platform.foodsteps.earth";
    } else {
      assertNever(foodstepsEnvironment, "Unknown environment");
    }

    try {
      Sentry.init({
        dsn,
        environment: (window as any).FOODSTEPS_ENVIRONMENT,
        integrations: [
          Sentry.httpClientIntegration(),
          Sentry.browserTracingIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.captureConsoleIntegration(),
        ],
        tracePropagationTargets: [
          new RegExp(`^${tracePropagationTarget}/(?!event-sender)`),
        ],
        // doesn't work :(
        // TODO: why is breadcrumbs 0? Make bigger for easier debugging
        maxBreadcrumbs: 0,
        // TODO: make configurable from backend and check what traces we might want to ignore
        tracesSampler: (samplingContext) => {
          if (samplingContext.name === "/oauth2/authorized") {
            return 0;
          } else {
            return 0.2;
          }
        },
        profilesSampleRate: 1,
      });
      monitoringInited = true;
    } catch (error) {
      console.error(error);
    }
  }
}

export function reportError(error: unknown) {
  if (monitoringInited) {
    Sentry.captureException(error);
  }
}

function sentryDsn(): string | null {
  const dsn: unknown = (window as any).FOODSTEPS_SENTRY_DSN;
  if (isString(dsn) && dsn.length > 0) {
    return dsn;
  } else {
    return null;
  }
}
