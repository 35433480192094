import isString from "lodash/isString";
import mixpanel from "mixpanel-browser";
import { useMemoOne } from "use-memo-one";

import {
  AddTagsTracking,
  CollectionAttributes,
  Dict,
  FilterProductsApplied,
  FunctionalUnitSetAttributes,
  ImpactCategorySetAttributes,
  IngredientLinkedAttributes,
  IngredientOriginChangedAttributes,
  LabelsDownloadTracking,
  PackagingComponentDeletedTracking,
  PackagingComponentSubmittedTracking,
  PageViewTracking,
  ProductsComparedAttributes,
  PromotionCodeAppliedAttributes,
  RecipeAttributes,
  RecipeEditIngredientTypeChangedTracking,
  RecipeEditStartedAttributes,
  RecipeEditSubmittedTracking,
  RecipeImpactsExportedAttributes,
  RecipeIngredientExpandedTracking,
  RecipeLabelExportTracking,
  RecipesUploadCompletedAttributes,
  RecipesUploadDuplicatesProcessedAttributes,
  RecipesUploadFileParsedAttributes,
  RecipesUploadServingsEnteredAttributes,
  RecipeViewTracking,
  RemoveTagsTracking,
  SignUpConsumptionCountrySubmittedAttributes,
  SignUpEndMileTypeQuestionAnsweredAttributes,
  TagCreatedAttributes,
  TagDeletedAttributes,
  TagRenamedAttributes,
  Tracking,
  TrackingProvider,
  UserAttributes,
  UserAttributesBeforeSignUp,
  UserCreatedAttributes,
} from ".";
import { FunctionalUnit } from "../domain/functionalUnits";
import assertNever from "../util/assertNever";

interface TrackingEvent {
  name: string;
  attributes: Dict;
}

interface MixpanelTrackingProviderProps {
  children: React.ReactNode;
}

export default function MixpanelTrackingProvider(
  props: MixpanelTrackingProviderProps
) {
  const { children } = props;

  const tracking = useMemoOne(() => createTracking(), []);

  return <TrackingProvider value={tracking}>{children}</TrackingProvider>;
}

function createTracking(): Tracking {
  let isInitialized = false;

  function init(): boolean {
    if (isInitialized) {
      return true;
    }

    const token = mixpanelToken();

    if (token === null) {
      return false;
    }

    mixpanel.init(token, {
      debug: (window as any).FOODSTEPS_ENVIRONMENT === "development",
      ...((window as any).FOODSTEPS_ENVIRONMENT === "development"
        ? {}
        : {
            api_host: (window as any).FOODSTEPS_PLATFORM_URI.slice(0, -1),
            api_routes: {
              track: "event-sender/track/",
              engage: "event-sender/engage/",
              groups: "event-sender/groups/",
            },
          }),
    });

    isInitialized = true;

    return true;
  }

  const track = (event: TrackingEvent): void => {
    if (init()) {
      mixpanel.track(event.name, event.attributes);
    }
  };

  const trackDecorator = <T,>(
    generateEvent: (tracking: T) => TrackingEvent
  ): ((tracking: T) => void) => {
    return (tracking: T) => {
      track(generateEvent(tracking));
    };
  };

  const trackAccessCommunityPlatform = (): void =>
    track({
      name: "Accessed Community Platform",
      attributes: {},
    });

  const trackEditTagsStarted = (): void =>
    track({
      name: "Edit Tags Started",
      attributes: {},
    });

  const trackEditTagsCompleted = (): void =>
    track({
      name: "Edit Tags Completed",
      attributes: {},
    });

  const trackFilterProductsStarted = (): void =>
    track({
      name: "Filter Tags Started",
      attributes: {},
    });

  const trackFilterProductsApplied = (tracking: FilterProductsApplied): void =>
    track({
      name: "Filter Tags Applied",
      attributes: {
        "Attention Needed": tracking.needsAttention,
        "Intersect Collections": tracking.intersectCollections,
        "Number Of Collections": tracking.numberOfCollections,
        "Selected Impact Ratings GHG":
          tracking.selectedGhgImpactRatings.join(", "),
        "Selected Impact Ratings Land Use":
          tracking.selectedLandUseImpactRatings.join(", "),
        "Selected Impact Ratings Water Use":
          tracking.selectedWaterUseImpactRatings.join(", "),
        "Selected Diets": tracking.selectedDiets.join(", "),
      },
    });

  const trackAddTags = (tracking: AddTagsTracking): void =>
    track({
      name: "Add Tags",
      attributes: {
        "Tag Name": tracking.tagName,
        Products: tracking.numberOfProducts,
      },
    });

  const trackRemoveTags = (tracking: RemoveTagsTracking): void =>
    track({
      name: "Remove Tags",
      attributes: {
        "Tag Name": tracking.tagName,
        Products: tracking.numberOfProducts,
      },
    });

  const trackPageViewed = trackDecorator(
    (tracking: PageViewTracking): TrackingEvent => ({
      name: "Page Viewed",
      attributes: {
        "Page Name": tracking.pageName,
        ...tracking.pageAttributes,
      },
    })
  );

  const trackRecipeLabelExported = trackDecorator(
    (tracking: RecipeLabelExportTracking): TrackingEvent => ({
      name: "Recipe Label Exported",
      attributes: {
        "GHG Per Serving": tracking.ghgPerServing,
        "Impact Rating": tracking.impactRating,
      },
    })
  );

  const trackLabelsDownloaded = trackDecorator(
    (tracking: LabelsDownloadTracking): TrackingEvent => ({
      name: "Labels Downloaded",
      attributes: {
        "Label Colour": tracking.colourSetting,
        "Number of Labels": tracking.numLabels,
        "Label Size": tracking.labelSize,
        "Label Type": tracking.labelType,
        "Packaging Included": tracking.includePackaging,
        Page: tracking.page,
      },
    })
  );

  const trackRecipeViewed = trackDecorator(
    (tracking: RecipeViewTracking): TrackingEvent => ({
      name: "Recipe Viewed",
      attributes: {
        "Page Parent": tracking.pageParent,
        ...serializeRecipeAttributes(tracking),
      },
    })
  );

  const trackRecipeIngredientExpanded = trackDecorator(
    (tracking: RecipeIngredientExpandedTracking): TrackingEvent => ({
      name: "Recipe Ingredients Expanded",
      attributes: {
        "Recipe Id": tracking.recipeId,
        "Recipe Name": tracking.recipeName,
        "Ingredient Id": tracking.ingredientId,
        "Ingredient Name": tracking.ingredientName,
      },
    })
  );

  const trackCollectionViewed = trackDecorator(
    (tracking: CollectionAttributes): TrackingEvent => ({
      name: "Collection Viewed",
      attributes: {
        "Collection Id": tracking.collectionId,
        "Collection Name": tracking.collectionName,
      },
    })
  );

  const trackCollectionDeleted = trackDecorator(
    (tracking: CollectionAttributes): TrackingEvent => ({
      name: "Collection Deleted",
      attributes: {
        "Collection Id": tracking.collectionId,
        "Collection Name": tracking.collectionName,
      },
    })
  );

  const trackCollectionCreationStarted = (): void =>
    track({
      name: "Collection Creation Started",
      attributes: {},
    });

  const trackCollectionCreationCompleted = trackDecorator(
    (tracking: CollectionAttributes): TrackingEvent => ({
      name: "Collection Creation Completed",
      attributes: {
        "Collection Id": tracking.collectionId,
        "Collection Name": tracking.collectionName,
      },
    })
  );

  const formatFunctionalUnit = (
    functionalUnit: FunctionalUnit,
    foodManufacturerOrganization: boolean
  ) => {
    if (functionalUnit === FunctionalUnit.KG) {
      return "Per KG";
    } else if (functionalUnit === FunctionalUnit.PORTION) {
      return foodManufacturerOrganization ? "Per product" : "Per serving";
    } else {
      assertNever(functionalUnit, "Unsupported FunctionalUnit");
    }
  };
  const trackFunctionalUnitSet = trackDecorator(
    (tracking: FunctionalUnitSetAttributes): TrackingEvent => ({
      name: "Functional Unit Set",
      attributes: {
        Chart: tracking.chart,
        "Functional Unit": formatFunctionalUnit(
          tracking.functionalUnit,
          tracking.foodManufacturerOrganization
        ),
      },
    })
  );

  const trackImpactCategorySet = trackDecorator(
    (tracking: ImpactCategorySetAttributes): TrackingEvent => ({
      name: "Impact Category Set",
      attributes: {
        Chart: tracking.chart ?? null,
        "Impact Category": tracking.impactCategory,
        "Page Name": tracking.pageName,
      },
    })
  );

  const trackIngredientLinked = trackDecorator(
    (tracking: IngredientLinkedAttributes): TrackingEvent => ({
      name: "Ingredient Linked",
      attributes: {
        "Ingredient Name": tracking.ingredientName,
        "Number of Occurrences": tracking.numOccurrences,
        "Food Class Recommended": tracking.recommendedFoodClass,
        "Recommended Food Class Selected":
          tracking.recommendedFoodClassSelected,
        "Food Class Selected": tracking.selectedFoodClass,
        Page: tracking.page,
      },
    })
  );

  const trackIngredientOriginChanged = trackDecorator(
    (tracking: IngredientOriginChangedAttributes): TrackingEvent => ({
      name: "Ingredient Origin Changed",
      attributes: {
        "Ingredient Name": tracking.ingredientName,
        "Ingredient Origin": tracking.ingredientOrigin,
      },
    })
  );

  const trackProductsCompared = trackDecorator(
    (tracking: ProductsComparedAttributes): TrackingEvent => ({
      name: "Products Compared",
      attributes: {
        "Product Name": tracking.productName,
        "Comparison Product Name": tracking.comparisonProductName,
      },
    })
  );

  const trackRecipeDeleted = trackDecorator(
    (tracking: RecipeAttributes): TrackingEvent => ({
      name: "Recipe Deleted",
      attributes: serializeRecipeAttributes(tracking),
    })
  );

  const trackRecipeEditStarted = trackDecorator(
    (tracking: RecipeEditStartedAttributes): TrackingEvent => {
      const name = "Recipe Edit Started";
      if (tracking.type === "add") {
        return { name, attributes: { "New Recipe": true } };
      } else if (tracking.type === "edit") {
        return {
          name,
          attributes: Object.assign(
            {},
            { "New Recipe": false },
            serializeRecipeAttributes(tracking)
          ),
        };
      } else {
        assertNever(tracking, "Unsupported type");
      }
    }
  );

  const trackRecipeEditIngredientTypeChanged = trackDecorator(
    (tracking: RecipeEditIngredientTypeChangedTracking): TrackingEvent => ({
      name: "Recipe Edit Ingredient Type Changed",
      attributes: {
        "Recipe Id": tracking.recipeId,
        "Recipe Name": tracking.recipeName,
        "New Ingredient Type": tracking.newIngredientType,
      },
    })
  );

  const trackRecipeEditSubmitted = trackDecorator(
    (tracking: RecipeEditSubmittedTracking): TrackingEvent => ({
      name: "Recipe Edit Submitted",
      attributes: {
        "New Recipe": tracking.newRecipe,
        "Recipe Id": tracking.recipeId,
        "Recipe Name": tracking.recipeName,
        "Simple Ingredients": tracking.simpleIngredientCount,
        "Subrecipe Ingredients": tracking.subrecipeIngredientCount,
      },
    })
  );

  const trackRecipeCopyStarted = trackDecorator(
    (tracking: RecipeAttributes): TrackingEvent => ({
      name: "Recipe Copy Started",
      attributes: serializeRecipeAttributes(tracking),
    })
  );

  const trackRecipeImpactsExported = trackDecorator(
    (tracking: RecipeImpactsExportedAttributes): TrackingEvent => ({
      name: "Recipe Impacts Exported",
      attributes: {
        "Number of Recipes": tracking.recipesExported,
        Page: tracking.page,
        Success: tracking.success,
      },
    })
  );

  const trackBulkRecipeUploadCompleted = trackDecorator(
    (tracking: RecipesUploadCompletedAttributes): TrackingEvent => ({
      name: "Recipes Uploaded in Bulk",
      attributes: {
        "Recipes Uploaded": tracking.recipes,
        "Exact Food Class Matches": tracking.exactFoodClassMatches,
        "Fuzzy Matched Food Classes": tracking.fuzzyMatchedFoodClasses,
        "Accepted Ingredient Matches": tracking.acceptedIngredientMatches,
        "Rejected Ingredient Matches": tracking.rejectedIngredientMatches,
        "Duplicates Handled Method": tracking.duplicateRecipeHandler,
        "Exact Packaging Component Matches":
          tracking.exactPackagingComponentMatches,
      },
    })
  );

  const trackBulkRecipeUploadDuplicatesProcessed = trackDecorator(
    (tracking: RecipesUploadDuplicatesProcessedAttributes): TrackingEvent => ({
      name: "Recipe Bulk Upload Duplicates Processed",
      attributes: {
        "Duplicates Processing": tracking.duplicateProcessing,
      },
    })
  );

  const trackBulkRecipeUploadFileParsed = trackDecorator(
    (tracking: RecipesUploadFileParsedAttributes): TrackingEvent => ({
      name: "Recipe Bulk Upload File Parsed",
      attributes: {
        "File Type": tracking.fileType,
        "Number of Recipes": tracking.recipes,
      },
    })
  );

  const trackBulkRecipeUploadServingsEntered = trackDecorator(
    (tracking: RecipesUploadServingsEnteredAttributes): TrackingEvent => ({
      name: "Recipe Bulk Upload Servings Entered",
      attributes: {
        "Servings Per Recipe": tracking.servingsPerRecipe,
      },
    })
  );

  const trackBulkRecipeUploadStarted = (): void =>
    track({
      name: "Recipe Bulk Upload Started",
      attributes: {},
    });

  const trackPromotionCodeApplied = trackDecorator(
    (tracking: PromotionCodeAppliedAttributes): TrackingEvent => ({
      name: "Promo Code Applied",
      attributes: {
        "Coupon Id": tracking.couponId,
        "Coupon Name": tracking.couponName,
        "Promo Code": tracking.promotionCode,
        "Promotion Id": tracking.promotionId,
        Success: tracking.success,
      },
    })
  );

  const trackPackagingComponentDeleted = trackDecorator(
    (tracking: PackagingComponentDeletedTracking): TrackingEvent => ({
      name: "Packaging Component Deleted",
      attributes: {},
    })
  );

  const trackPackagingComponentSubmitted = trackDecorator(
    (tracking: PackagingComponentSubmittedTracking): TrackingEvent => ({
      name: "Packaging Component Submitted",
      attributes: {
        "New Packaging Component": tracking.isNew,
      },
    })
  );

  const trackSignUpPersonalDetailsSubmitted = () =>
    track({
      name: "Sign Up Personal Details Submitted",
      attributes: {},
    });

  const trackSignUpConsumptionCountrySubmitted = trackDecorator(
    (tracking: SignUpConsumptionCountrySubmittedAttributes): TrackingEvent => ({
      name: "Sign Up Consumption Country Submitted",
      attributes: {
        "Consumption Country": tracking.countryName,
      },
    })
  );

  const trackSignUpEndMileTypeQuestionAnswered = trackDecorator(
    (tracking: SignUpEndMileTypeQuestionAnsweredAttributes): TrackingEvent => ({
      name: "Sign Up Question Answered",
      attributes: {
        "Sign Up Question": tracking.question,
        "Sign Up Question Answer": tracking.answer,
      },
    })
  );

  const trackSignUpAcknowledgementSubmitted = () =>
    track({
      name: "Sign Up Acknowledgement Submitted",
      attributes: {},
    });

  // Tags tracking events
  const trackManageTagsStarted = () =>
    track({
      name: "Manage Tags Started",
      attributes: {},
    });

  const trackManageTagsCompleted = () =>
    track({
      name: "Manage Tags Completed",
      attributes: {},
    });

  const trackTagCreated = trackDecorator(
    (tracking: TagCreatedAttributes): TrackingEvent => ({
      name: "Tag Created",
      attributes: {
        "Tag Name": tracking.tagName,
        "Tag ID": tracking.tagId,
        Workflow: tracking.workflow,
      },
    })
  );

  const trackTagDeleted = trackDecorator(
    (tracking: TagDeletedAttributes): TrackingEvent => ({
      name: "Tag Deleted",
      attributes: {
        "Tag Name": tracking.tagName,
        "Tag ID": tracking.tagId,
      },
    })
  );

  const trackTagRenamed = trackDecorator(
    (tracking: TagRenamedAttributes): TrackingEvent => ({
      name: "Tag Renamed",
      attributes: {
        "New Tag Name": tracking.newTagName,
        "Old Tag Name": tracking.previousTagName,
        "Tag ID": tracking.tagId,
      },
    })
  );

  const trackUserCreated = trackDecorator(
    (tracking: UserCreatedAttributes): TrackingEvent => ({
      name: "User Created",
      attributes: {
        Email: tracking.email,
        "First Name": tracking.firstName,
        "Last Name": tracking.lastName,
      },
    })
  );

  const identifyUserInternal = (
    userAttributes: Partial<UserAttributes>
  ): void => {
    const {
      email,
      endMileTypeName,
      endOfLifeType,
      firstName,
      foodManufacturer,
      impersonatedExternalUserId,
      isStaff,
      isExternalUser,
      lastName,
      organizationId,
      organizationName,
      postPreparationStorageScenarioName,
      productTier,
      recipeCountLimit,
      userId,
    } = userAttributes;

    if (init()) {
      // If no userId is provided, Mixpanel will automatically generate a random distinct_id.
      const distinctId = userId ?? undefined;
      mixpanel.identify(distinctId);
      mixpanel.people.set({
        $distinct_id: distinctId,
        $email: email,
        "End Mile Type": endMileTypeName,
        "End of Life Type": endOfLifeType,
        "First Name": firstName,
        "Food Manufacturer": foodManufacturer,
        "Is External User": isExternalUser,
        "Is Staff": isStaff,
        "Last Name": lastName,
        "Organisation Name": organizationName,
        "Post Preparation Storage Scenario": postPreparationStorageScenarioName,
        "Product Tier": productTier,
        "Recipe Allowance": recipeCountLimit,
        $name: `${firstName} ${lastName}`,
      });
      mixpanel.register({
        $email: email,
        "End Mile Type": endMileTypeName,
        "End of Life Type": endOfLifeType,
        "Food Manufacturer": foodManufacturer,
        "Organisation Id": organizationId,
        "Organisation Name": organizationName,
        "Post Preparation Storage Scenario": postPreparationStorageScenarioName,
        "Product Tier": productTier,
        "User Impersonated": impersonatedExternalUserId,
      });
    }
  };

  const identifyUserBeforeSignUp: (
    userAttributes: UserAttributesBeforeSignUp
  ) => void = identifyUserInternal;
  const identifyUser: (userAttributes: UserAttributes) => void =
    identifyUserInternal;

  const getDistinctId: () => string | undefined = () => {
    if (init()) {
      return mixpanel.get_distinct_id().toString();
    }
  };

  return {
    getDistinctId,
    identifyUser,
    identifyUserBeforeSignUp,
    trackAccessCommunityPlatform,
    trackAddTags,
    trackEditTagsStarted,
    trackEditTagsCompleted,
    trackFilterProductsStarted,
    trackFilterProductsApplied,
    trackRemoveTags,
    trackPageViewed,
    trackRecipeLabelExported,
    trackLabelsDownloaded,
    trackRecipeViewed,
    trackRecipeIngredientExpanded,
    trackCollectionViewed,
    trackCollectionDeleted,
    trackCollectionCreationStarted,
    trackCollectionCreationCompleted,
    trackFunctionalUnitSet,
    trackImpactCategorySet,
    trackIngredientLinked,
    trackIngredientOriginChanged,
    trackManageTagsCompleted,
    trackManageTagsStarted,
    trackTagCreated,
    trackTagDeleted,
    trackTagRenamed,
    trackProductsCompared,
    trackRecipeCopyStarted,
    trackRecipeDeleted,
    trackRecipeEditStarted,
    trackRecipeEditIngredientTypeChanged,
    trackRecipeEditSubmitted,
    trackRecipeImpactsExported,
    trackSignUpPersonalDetailsSubmitted,
    trackSignUpConsumptionCountrySubmitted,
    trackSignUpEndMileTypeQuestionAnswered,
    trackSignUpAcknowledgementSubmitted,
    trackBulkRecipeUploadCompleted,
    trackBulkRecipeUploadDuplicatesProcessed,
    trackBulkRecipeUploadFileParsed,
    trackBulkRecipeUploadServingsEntered,
    trackBulkRecipeUploadStarted,
    trackPackagingComponentDeleted,
    trackPackagingComponentSubmitted,
    trackPromotionCodeApplied,
    trackUserCreated,
  };
}

function serializeRecipeAttributes(attributes: RecipeAttributes) {
  return {
    "Recipe Id": attributes.recipeId,
    "Recipe Name": attributes.recipeName,
  };
}

function mixpanelToken(): string | null {
  const token: unknown = (window as any).FOODSTEPS_MIXPANEL_TOKEN;
  if (isString(token) && token.length > 0) {
    return token;
  } else {
    return null;
  }
}
